<template>
  <div
    class="modal fade view-job-dialog"
    id="viweJob"
    tabindex="-1"
    aria-labelledby="modelTitleId"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            :style="`background-image: url(${modalTitle})`"
          >
            <span>{{ viewdJob.titles.en }}</span>

            <div class="share-box">
              <span>Share on:</span>
              <ShareNetwork
                v-for="(social, i) in socialsItems"
                :key="i"
                :network="social.net"
                :url="social.link"
                :title="`${viewdJob.titles.en}  vacancy is now open at Strata Company, check the link below for more details`"
                :description="`Job Description: ${viewdJob.description}  Responsibilities: ${viewdJob.Responsibilities} Exprince Level : ${viewdJob.experince_level} Requierments: ${viewdJob.requirment}   Location: ${viewdJob.location.en} `"
                hashtags="Strata,Hiring,Jobs"
                :popup="{ width: 400, height: 200 }"
              >
                <p>
                  <font-awesome-icon
                    :icon="[social.net == 'email' ? 'fa' : 'fab', social.icon]"
                  />
                </p>
                <span>
                  {{ social.name }}
                </span>
              </ShareNetwork>
            </div>
          </h5>
        </div>
        <div class="modal-cards mt-5">
          <div class="flex-wrap justify-content-around d-flex">
            <div
              class="
                modal-card
                m-2
                d-flex
                justify-content-between
                flex-row-reverse
              "
              v-if='viewdJob.location.en'
            >
              <div class="text-start">
                <div class="banner-text d-none d-sm-inline">Location:</div>
                <div class="modal-values">{{ viewdJob.location.en }}</div>
              </div>
              <div>
                <font-awesome-icon :icon="['fa', 'map-marker-alt']" size="lg" />
              </div>
            </div>
            <div
              class="
                modal-card
                m-2
                d-flex
                justify-content-between
                flex-row-reverse
              "
              v-if='viewdJob.type'
            >
              <div class="text-start">
                <div class="banner-text d-none d-sm-inline">Job Type:</div>
                <div class="modal-values">
                  {{ viewdJob.type }}
                </div>
              </div>
              <div>
                <font-awesome-icon :icon="['fa', 'briefcase']" size="lg" />
              </div>
            </div>
            <div
              class="
                modal-card
                m-2
                d-flex
                justify-content-between
                flex-row-reverse
                
              "
              v-if='viewdJob.experince_level'
            >
              <div class="text-start">
                <div class="banner-text d-none d-sm-inline">
                  Exprince Level:
                </div>
                <div class="modal-values">{{ viewdJob.experince_level }}</div>
              </div>
              <div>
                <font-awesome-icon :icon="['fa', 'chart-line']" size="lg" />
              </div>
            </div>
            <div
              class="
                modal-card
                m-2
                d-flex
                justify-content-between
                flex-row-reverse
              "
              v-if='viewdJob.salary_avrage.from || viewdJob.salary_avrage.to'
            >
              <div class="text-start">
                <div class="banner-text d-none d-sm-inline">Salary Range:</div>
                <div class="modal-values">
                  {{ viewdJob.salary_avrage.from }} -
                  {{ viewdJob.salary_avrage.to }}
                </div>
              </div>
              <div>
                <font-awesome-icon :icon="['fa', 'funnel-dollar']" size="lg" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <hr />
          <div class="desc">
            <p class="fw-bold m-0">Description</p>
            <p class="ps-3 m-0">
              {{ viewdJob.descriptions.en }}
            </p>
          </div>
          <div class="requirements" v-if='viewdJob.requirements'>
            <p class="fw-bold m-0 mt-3">Requirements</p>
            <div v-html="viewdJob.requirment"></div>
          </div>
          <div class="responsibilities" v-if='viewdJob.Responsibilities'>
            <p class="fw-bold m-0 mt-3">Responsibilities</p>
            <div v-html="viewdJob.Responsibilities"></div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-sec" @click="closeModal">Close</button>
          <a
            class="btn btn-pri"
            :href="`mailto:${
              viewdJob.email ? viewdJob.email : settings.email
            }?subject=About ${viewdJob.titles.en} Job`"
          >
            Apply
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["viewdJob", "listJob"],
  mounted() {
    if (this.viewdJob && this.viewdJob.id) {
      this.$loadScript("../../js/bootstrap.bundle.js").then((res) => {
        this.myModal = new bootstrap.Modal(document.getElementById("viweJob"));
        this.myModalEle = document.getElementById("viweJob");
        if (this.listJob.image_url) {
          this.modalTitle = this.listJob.image_url;
        }
        this.myModalEle.addEventListener(
          "hidden.bs.modal",
          this.handleModalhidden
        );
        // console.log(this.myModal)
        this.openModal();
      });
    } else {
      this.$router.replace({
        name: "Hiring",
      });
    }
  },
  data() {
    return {
      modalTitle: require("../../assets/images/modal-title.jpg"),
      myModal: "",
      shareBox: false,
      socialsItems: [
        {
          name: "Linked In",
          icon: "linkedin-in",
          link: window.location.origin + this.$route.fullPath,
          net: "linkedin",
        },
        {
          name: "Facebook",
          icon: "facebook-f",
          link: window.location.origin + this.$route.fullPath,
          net: "facebook",
        },
        {
          name: "Twitter",
          icon: "twitter",
          link: window.location.origin + this.$route.fullPath,
          net: "twitter",
        },
        // {
        //   name: "E-mail",
        //   icon: "envelope",
        //   link: window.location.origin + this.$route.fullPath,
        //   net: "email",
        // },
      ],
    };
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings;
    },
  },
  methods: {
    shareBoxToggle() {
      this.shareBox = !this.shareBox;
    },
    openModal(project) {
      this.myModal.show();
    },
    closeModal() {
      this.myModal.hide();
    },
    handleModalhidden() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-footer {
  .btn-sec,
  .btn-pri {
    color: #fff;
    padding: 15px 25px !important;
    background-color: #28656b !important;
    &:focus,
    &:hover {
      background-color: #28656b !important;
      box-shadow: none !important;
      opacity: 0.8;
    }
    @media (max-width: 768px) {
      padding: 0.5rem 3rem !important;
      margin: 5px auto !important;
    }
  }
}
</style>